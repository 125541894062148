










































































































import { Component, Vue } from "vue-property-decorator";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({})
export default class LoginView extends Vue {
  username = "";
  password = "";
  showLogin = false;

  get valid() {
    return !!this.username && !!this.password;
  }

  get rulesValid() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
    ];
  }

  get isLoginInProgress(): boolean {
    return this.$store.state.userStore.isLoginInProgress;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("login.documentTitle")
    );
  }

  public login() {
    const params = {
      username: this.username,
      password: this.password,
    };

    this.$emit("login", params);
  }
}
